/**
 * Created by teodor on 1/31/16.
 */
var MantisCookie = require('./mantis.cookie');

var MantisCommon = (function ($, _, MantisCookie) {
    'use strict';

    return {
        /**
         * Show Login button if instance cookie is set
         * @returns {boolean}
         */
        renderLoginButton: function(){
            if(_.isUndefined(MantisCookie.getMantisHubInstance())){
                return false;
            }
            $('.btn-login').attr('href', MantisCookie.getMantisHubInstance()).show();
        },
        initCarousel: function(){
            $(document).ready(function(){
                var carouselItems = $(".carousel");
                if(carouselItems.length > 0){
                    carouselItems.each(function(i, el){
                        $(el).owlCarousel({
                            loop: $(el).find('.item').length > 0,
                            margin: 20,
                            donts: true,
                            autoplay: false,
                            responsiveClass: true,
                            responsive:{
                                0:{
                                    items: 1,
                                    nav: true
                                },
                                1024:{
                                    items: 3,
                                    nav: true
                                }
                            },
                            onResized: function (){
                                updateSize();
                            },
                            onInitialized: function(){
                                updateSize();
                            }
                        });
                    });
                }

                function updateSize(){
                    var minHeight = parseInt(carouselItems.find('.owl-item').eq(0).css('height'));

                    carouselItems.find('.owl-item').each(function () {
                        var thisHeight = parseInt($(this).css('height'));
                        minHeight = minHeight <= thisHeight ? minHeight : thisHeight;
                    });

                    carouselItems.find('blockquote').css('height', minHeight + 'px');
                }
            });
        }
    };
}($, _, MantisCookie));

$( document ).ready(function() {
    MantisCommon.renderLoginButton();
    MantisCommon.initCarousel();
});