/**
 * Created by svet on 9/29/16.
 */
var MantisFeatures = (function ($, _) {
	'use strict';

	return {
		init: function() {
			var
				$window= $(window),
				win_height_padded = $window.height() * 1.1,
				features = $(".features > .feature-description:not(.ani-processed) div"),
				header_height = $('.header').outerHeight();

			function dsplay_features() {
			    var scroll_position = $window.scrollTop();

			    features.each(function() {
			        var $this = $(this),
			            offsetTop = $this.offset().top;

			        if (scroll_position + win_height_padded > offsetTop) {
			            $this.addClass("ani-processed"); 
			        } 
			    });
			}

			dsplay_features();
			$(window).scroll(function(event) {
			    dsplay_features();
			});

			$('.section-menu a[href^="#"]').on('click',function (e) {
			    e.preventDefault();
			    var target = this.hash,
			        $target = $(target),
			        $selected = $(this);

			    $('html, body').stop().animate({
			        'scrollTop': $target.offset().top - header_height
			    }, 900, 'swing', function () {
			        $selected.parent().addClass('active').siblings().removeClass('active');
			        window.location.hash = target;
			    });
			});
		}
	}

}($, _));

$( document ).ready(function() {
    MantisFeatures.init();
});