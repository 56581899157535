/**
 * Created by teodor on 7/717/16.
 */
var MantisCalculator = (function ($, _) {
    'use strict';

    return {
        /**
         * Initializes calculator
         */
        init: function(){
            var self = this;
            var showAnnualPrice = true;
            var priceTypeSwitch = $('.switch input');

            if(priceTypeSwitch.length > 0){
                showAnnualPrice = priceTypeSwitch.is(':checked');
                priceTypeSwitch.change(function(){
                    showAnnualPrice = this.checked;
                    $(priceTypeSwitch.not(this)).prop('checked', showAnnualPrice);
                    self.changePriceTypeView();
                });
            }

            if(!$('#pricing-calculator').length){
                return false;
            }

            $('#pricing-calculator').submit(function(e){
                e.preventDefault();
                var priceResultMessage = 'costing %s per month or %s annually';
                var numberOfUsers = parseInt($('#pricing-calculator').find('input').val());

                $(this).find('.input-group').removeClass('has-error');
                if(isNaN(numberOfUsers)){
                    $(this).find('.input-group').addClass('has-error');
                    return $('#calculator-recommendation').html('<p class="text-danger">Number of users provided is not a number, please put the number of users as an integer</p>').show();
                }

                var annualTransitionPoints = {
                    'gold': 26,
                    'platinum': 131,
                    'platinum200': 221,
                    'platinum300': 326,
                    'platinum500': 566,
                };

                var monthlyTransitionPoints = {
                    'gold': 26,
                    'platinum': 106,
                    'platinum200': 221,
                    'platinum300': 316,
                    'platinum500': 566,
                };

                var transitionPoints = showAnnualPrice ? annualTransitionPoints : monthlyTransitionPoints;

               if( numberOfUsers <= transitionPoints.gold ) {
                    var upperLimitOfUsers = 15
                    var plan = 'Gold';
                    var planPrice = 35.95;
                    var planPriceAnnual = 330;
                    var storageAllowance = '5GB';
                } else if( numberOfUsers <= transitionPoints.platinum ) {
                    var upperLimitOfUsers = 30;
                    var plan = 'Platinum';
                    var planPrice = 74.95;
                    var planPriceAnnual = 660;
                    var storageAllowance = '10GB';
                }else if(numberOfUsers <= transitionPoints.platinum200){
                    var upperLimitOfUsers = 200;
                    var plan = 'Platinum 200';
                    var planPrice = 300;
                    var planPriceAnnual = 3200;
                    var storageAllowance = '20GB';
                }else if(numberOfUsers <= transitionPoints.platinum300){
                    var upperLimitOfUsers = 300;
                    var plan = 'Platinum 300';
                    var planPrice = 375;
                    var planPriceAnnual = 3800;
                    var storageAllowance = '30GB'
                }else if(numberOfUsers <= 566){
                    var upperLimitOfUsers = transitionPoints.platinum500;
                    var plan = 'Platinum 500';
                    var planPrice = 425;
                    var planPriceAnnual = 4500;
                    var storageAllowance = '50GB';
                }else if(numberOfUsers <= 1000){
                    var upperLimitOfUsers = 1000;
                    var plan = 'Platinum 1000';
                    var planPrice = 600;
                    var planPriceAnnual = 6300;
                    var storageAllowance = '100GB';
                }else{
                    $('#calculator-recommendation').html('<p class="text-info">For our customers that have more than 1000 users, please <a href="mailto:support@mantishub.com">contact us</a> today and we\'ll create a custom quote for you</p>').show();
                    return;
                }

                if(numberOfUsers > upperLimitOfUsers){
                    var packs = Math.ceil((numberOfUsers - upperLimitOfUsers) / 5);
                    planPrice = planPrice + ( packs * 15 );
                    planPriceAnnual = planPriceAnnual + ( packs * 132 );
                }

                if(showAnnualPrice) {
                    planPrice = planPriceAnnual / 12;
                    priceResultMessage = 'costing %s per month (paid annually)';
                }

                if(_.isUndefined(window.Intl)){
                    planPrice = '$' + planPrice;
                    planPriceAnnual = '$' + planPriceAnnual;
                }else{
                    var formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                    });

                    planPrice = formatter.format(planPrice);
                    planPriceAnnual = formatter.format(planPriceAnnual);
                }

                $('#calculator-recommendation').html('<p class="text-success"><strong>MantisHub Recommendation:</strong><br/> ' + plan + ' plan including up to ' + upperLimitOfUsers + ' users' + (packs > 0 ? ' and ' + packs + ' user pack' + (packs > 1 ? 's' : '') : '') + ', ' + self.sprintf(priceResultMessage, planPrice, planPriceAnnual) + ' and includes ' + storageAllowance  + ' of storage</p>').show();
            })
        },
        changePriceTypeView: function(){
            $('.monthly,.annual').toggle();
        },
        sprintf: function(format){
            for(var i=1; i < arguments.length; i++){
                format = format.replace(/%s/, arguments[i]);
            }

            return format;
        }
    };
}($, _));

$( document ).ready(function() {
    MantisCalculator.init();
});