var MantisCookie = require('./mantis.cookie');

/**
 * Created by teodor on 1/17/16.
 */
String.prototype.ucfirst = function(){
    return this ? this.charAt(0).toUpperCase() + this.substr(1) : this;
}

var App = (function ($, _, Backbone, MantisCookie) {
    'use strict';

    var SignUpModel = Backbone.Model.extend({

        validation: {
            subdomain: [{
                required: true,
                msg: 'Subdomain is required'
            },
            {
            fn: function(value, attr, computedState) {
                var regex = /[^a-zA-Z0-9\-]/;
                if(regex.test(value)){
                    return 'Invalid subdomain';
                }

                if(_.indexOf(this.get('_takenSubdomains'), value) >= 0){
                    $('input[name=subdomain]')
                        .closest('.input-group')
                        .find('.input-group-addon').html('<i class="fa fa-times"></i>');
                    return value + ' is already taken';
                }


                $('input[name=subdomain]')
                    .closest('.input-group')
                    .find('.input-group-addon').html('<i class="fa fa-check"></i>');
            }
            },
            {
                maxLength: 32,
                msg: "Instance name should be less than 32 characters"
            },
            ],
            domain: {
                required: true,
                msg: 'Domain is required'
            },
            firstName: {
                required: true,
                msg: 'First name is required'
            },
            lastName: {
                required: true,
                msg: 'Last name is required'
            },
            email: [{
                required: true,
                msg: 'Email is required'
            },
            {
                pattern: 'email',
                msg: 'Email is invalid'
            }
            ],
            password: [{
                required: true,
                msg: 'Password is required'
            }],
            confirm_password: {
                fn: function(value, attr, computedState) {
                    if($('input[name=password]').val() != value){
                        return 'Passwords do not match'
                    }

                }
            },
        },
        initialize: function(){
            this.set('_takenSubdomains', []);
        },
        /**
         * Checks if address (domain + subdomain is available)
         *
         * @param subdomain
         * @param domain
         * @returns {SignUpModel}
         */
        setCheckAvailabilityRoute: function (subdomain, domain){
            this.url = '/api/instance/' + subdomain + '/availability';
            return this;
        },

        /**
         * Referral code validation route
         *
         * @param referralCode
         * @returns {SignUpModel}
         */
        setCheckReferralCodeAvailabilityRoute: function (referralCode){
            this.url = '/api/referral-code/' + referralCode;
            return this;
        },
        setCreateRoute: function (){
            this.url = '/api/instance';
            return this;
        },
        setCheckStatusRoute: function (subdomain){
            this.url = '/api/instance/' + subdomain + '/status/' + this.get('email');
            return this;
        },
        /**
         * Returns the url of the login page of the newly created instance
         * @returns {string}
         */
        getLoginUri: function(){
            return 'https://' + this.get('subdomain') + '.' + this.get('domain') + '/login_page.php';
        },
        /**
         * Generating instance url with SSO
         * @returns {string}
         */
        getInstanceWithSSO: function(){
            var password = md5(this.get('password'));
            var token = md5(this.get('email') + password);
            var resource = '/plugin.php?page=MantisHub/sso&token=' + token;
            var ssoUrl = 'https://' + this.get('subdomain') + '.' + this.get('domain') + resource;

            //@todo temp. workaround so we can test, remove
            if(this.get('domain') == 'mantishub.io'){
                ssoUrl = 'https://' + this.get('subdomain') + '.' + this.get('domain') + resource;
            }

            return ssoUrl;
         }
    });

    var SignUpView = Backbone.View.extend({
        el: '#signup-container',
        model: new SignUpModel,
        plan: null,
        gaEvent: 'Signup Submit',
        initialize: function () {
            Backbone.Validation.bind(this);

            this.plan = window.location.pathname.split('/')[2];

            if(this.plan){
                $('header').hide();
                $('footer').hide();
            }
            this.render = _.wrap(this.render, function(render) {
                this.beforeRender();
                render();
                this.afterRender();
            }, this);

            this.render();
        },
        /**
         * Events
         */
        events: {
            "click button[type=button]": 'onSignUp',
            'keyup input[name=subdomain]': 'onKeyup',
            'blur input': 'onInputLeave',
            'change select[name=domain]': 'onDomainChange'

        },
        /**
         * Prepare view for rendering
         */
        beforeRender: function () {
        },
        /**
         * Render view
         */
        render: function(){
            if($("#signup-form-template").length > 0){
                var template = _.template( $("#signup-form-template").html());
                $('#signup-container').html(template);
            }
        },
        afterRender: function () {
            this.delegateEvents();
            this.$el.find('#plan-label').html('(' + this.plan + ')');
        },

        addErrorMessage: function($el, message){
            $el.closest('.form-group').addClass('has-error').append('<span class="help-block">' + message + '</span>');
            return this;
        },
        removeErrorMessage: function($el){
            $el.closest('.form-group').find('.help-block').remove();
            $el.closest('.form-group').removeClass('has-error');
        },
        renderValidation: function(validation){
            _.each(validation, function(message, element){
                this.addErrorMessage(this.$el.find('input[name=' + element + ']'), message);
            }, this);
        },
        /**
         * When user clicks submit / signup
         * Event handler.
         */
        onSignUp : function () {
            this.$el.find('.alert-danger').remove();
            this.$el.removeClass('.has-error');
            this.$el.find('.help-block').remove();

            var response = grecaptcha.getResponse();
            if(response.length == 0) {
                var html ='<div class="alert alert-danger" role="alert"> ' +
                    '<i class="fa fa-exclamation-circle"></i> Please verify that you are a Human.</div>';

                this.$el.find('button[type=button]').parent().append(html);
                return false;
            }

            var self = this;
            this.model.set('domain', this.$el.find('select[name=domain]').val());
            this.model.set('subdomain', this.$el.find('input[name=subdomain]').val());
            this.model.set('firstName', this.$el.find('input[name=firstName]').val());
            this.model.set('lastName', this.$el.find('input[name=lastName]').val());
            this.model.set('email', this.$el.find('input[name=email]').val());
            this.model.set('password', this.$el.find('input[name=password]').val());
            this.model.set('confirm_password', this.$el.find('input[name=confirm_password]').val());
            this.model.set('preferred_region', this.$el.find('input[name=preferred_region]:checked').val());
            this.model.set('planCode', this.plan);

            var validation = this.model.validate();
            this.renderValidation(validation);

            if(!this.model.isValid()){
                return false;
            }

            self.$el.find('.signup-wrapper').hide();
            self.$el.find('.loading').show();
            this.model.setCreateRoute().save().done(_.bind(function(model){
                ga && ga('send', 'event', self.gaEvent, self.getPlanType());
                MantisCookie.setMantisHubInstance(self.model.getLoginUri());
                window.location.replace(self.model.getInstanceWithSSO());
            }), this)
                .fail(_.bind(function(model, bar){
                    self.$el.find('.signup-wrapper').show();
                    self.$el.find('.loading').hide();
                    var html ='<div class="alert alert-danger" role="alert"> ' +
                        '<i class="fa fa-exclamation-circle"></i> ' + model.responseJSON.error +
                        '</div>';

                    this.$el.find('button[type=button]').parent().append(html);
                }, this));

        },

        /**
         * Catches on blur events of the sign up form
         * Event Handler
         *
         * @param e
         * @returns {boolean}
         */
        onInputLeave: function(e){
            var name = $(e.currentTarget).attr('name');
            var value = $(e.currentTarget).val();
            var errorMessage = this.model.preValidate(name, value);

            this.removeErrorMessage($(e.currentTarget));

            if(_.isEmpty(errorMessage)){
                return true;
            }

            var error = {};
            error[name] = errorMessage;
            this.renderValidation(error);
        },
        /**
         * Catches input as user types and checks subdomain availability if longer than 5 characters
         * Event handler.
         * @param e
         * @returns {boolean}
         */
        onKeyup: function(e) {

            var $el = $('input[name=subdomain]').parent().parent();

            var val = $(e.currentTarget).val();
            var errorMessage = this.model.preValidate('subdomain', val);

                $(e.currentTarget)
                    .closest('.input-group')
                    .find('.input-group-addon').html('<i class="fa fa-link"></i>');

            if(!_.isEmpty(errorMessage)){
                this.removeErrorMessage($('input[name=subdomain]').parent());
                var error = {};
                $('input[name=subdomain]')
                    .closest('.input-group')
                    .find('.input-group-addon').html('<i class="fa fa-times"></i>');
                error['subdomain'] = errorMessage;
                this.renderValidation(error);
                return false;
            }

            this.model.setCheckAvailabilityRoute(val, this.$el.find('select[name=domain]').val())
                .fetch()
                .done(_.bind(function(){
                    $el.find('.status').remove();
                    if(this.model.get('code') == 'unavailable'){

                        this.removeErrorMessage($('input[name=subdomain]').parent());

                        $('input[name=subdomain]')
                            .closest('.input-group')
                            .find('.input-group-addon').html('<i class="fa fa-times"></i>');


                        var message = $(e.currentTarget).val() + ' is already taken.';
                        this.model.set('_takenSubdomains', []);
                        this.model.get('_takenSubdomains').push($(e.currentTarget).val());
                        this.renderValidation({'subdomain': message});
                    }else{
                        this.removeErrorMessage($('input[name=subdomain]').parent());
                    }
                }, this));
        },
        onDomainChange: function(e){
            this.$el.find('input[name=subdomain]').keyup();
        },
        getPlanType: function(){
            return this.model.get('plan');
        }

    });

    return {
        init: function(){
            var signUp = new SignUpView;
        }

    };
}($, _, Backbone, MantisCookie));

$( document ).ready(function() {
    App.init();
});
