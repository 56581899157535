/**
 * Created by teodor on 1/31/16.
 */
var MantisCookie = (function (Cookies, $, _) {
    'use strict';

    return {
        setMantisHubInstance: function(instanceUrl){
            Cookies.set('mantishubInstanceUrl', instanceUrl);
        },
        getMantisHubInstance: function(){
            return Cookies.get('mantishubInstanceUrl');
        }

    };
}(Cookies, $, _));

module.exports = MantisCookie;
